do($ = jQuery) ->
  $ ->
    $user_profile_form = $('#user-profile-form')
    if $user_profile_form.length
      $form_item_pass = $('.form-item-pass')
      $form_item_current_pass = $('.form-item-current-pass')
      $form_item_mail_element = $('.form-item-mail input[name="mail"]')

      $form_item_pass.hide() if !$form_item_pass.find('input').hasClass 'error'
      $form_item_current_pass.hide() if !$form_item_current_pass.find('input').hasClass 'error'

      $current_email = $form_item_mail_element.val()
      $form_item_mail_element.on 'input propertychange', ->
        if $(this).val() != $current_email || $form_item_pass.is(':visible') then $form_item_current_pass.show() else $form_item_current_pass.hide()

      $change_password_link = $('<span />').addClass('change-password').text('Change your password').click ->
        $form_item_pass.show()
        $form_item_current_pass.show()
        $(this).remove()
      $form_item_pass.after $change_password_link

    $user_add_form = $('#user-register-form')
    if $user_add_form.length
      $companies = [
        "Surrey County Council"
        "TK Maxx"
        "Unilever"
        "Bentalls"
        "Kingston College"
        "Primark"
        "Wilko"
        "Kingfisher"
        "John Lewis"
        "Kingston University"
        "RBK"
        "Marks & Spencer"
        "Boots"
        "British Heart Foundation"
        "Zara"
        "W H Smith"
        "Superdry"
        "H&M"
        "Hollister"
        "Barclays"
        "JD Sports"
        "Next"
        "River Island"
        "Apple"
        "Fat Face"
        "Hema"
      ]
      $input = $user_add_form.find '[name*=field_place_of_work]'
      $input.attr 'list', 'companies'
      $input.after '<datalist id ="companies">'+('<option value="'+c+'">' for c in $companies).join('')+'</datalist>'
